<template>
  <v-container v-if="$store.state.auth.user">
    <v-row justify="center">
      <v-col md="12" lg="6">
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $store.state.auth.user.email }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  E-Mail
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $store.state.auth.user.createdAt | formatDate }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Date de création du compte
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="$store.state.auth.user.company"></v-divider>
            <v-list-item v-if="$store.state.auth.user.company">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $store.state.auth.user.company.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Entreprise associé
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    v-for="(role, i) in $store.state.auth.user.roles"
                    :key="i"
                  >
                    <span v-if="i < $store.state.auth.user.roles.length - 1">{{ role | prettyRoleName }}, </span>
                    <span v-else>{{ role | prettyRoleName }}</span>
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Rôles
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
          <v-card class="mt-3">
            <v-card-title>
              <span class="headline">
                <v-icon>mdi-account-circle</v-icon>
                Préférences utilisateur
              </span>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-switch v-model="$vuetify.theme.dark" label="Thème sombre" />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-switch v-model="debugMode" label="Débogage" />
                  </v-col>
                </v-row>
                <v-text-field
                  label="Mot de passe actuel"
                  v-model="currentPassword"
                  type="password"
                >
                </v-text-field>
                <v-text-field
                  label="Nouveau mot de passe"
                  v-model="newPassword"
                  type="password"
                >
                </v-text-field>
                <v-text-field
                  label="Adresse e-mail"
                  v-model="email"
                  type="text"
                >
                </v-text-field>
              </v-form>

            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text :loading="loading" @click="save">
                Sauvegarder
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    email: '',
    newPassword: '',
    currentPassword: '',
    debugMode: false,
  }),
  mounted () {
    if (this.$store.state.auth.user && this.$store.state.auth.user.email) {
      this.email = this.$store.state.auth.user.email
    }
    this.debugMode = this.$store.state.debugMode
  },
  watch: {
    '$vuetify.theme.dark' (val) {
      this.$store.commit('setTheme', val ? 'dark' : 'light')
    },
    '$store.state.auth.user.email' (val) {
      this.email = val
    },
    'debugMode' (val) {
      this.$store.commit('setDebugMode', val)
    },
    '$store.state.debugMode' (val) {
      this.debugMode = val
    }
  },
  methods: {
    async save () {
      this.loading = true
      let newEmail = this.email.length > 0 ? this.email : this.$store.state.auth.user.email
      let newPassword = this.newPassword.length > 0 ? this.newPassword : null

      // first, we make sure the current password is correct
      try {
        await this.$store.dispatch('auth/login', {
          email: this.$store.state.auth.user.email,
          password: this.currentPassword
        })
      } catch (e) {
        this.loading = false
        this.$store.commit('alert/add', {
          text: 'Mot de passe actuel erroné',
          color: 'error'
        })
        return
      }

      // then we update the user
      try {
        await this.$store.dispatch('users/update', {
          ...this.$store.state.auth.user,
          email: newEmail,
          newPassword
        })
      } catch (e) {
        this.loading = false
        this.$store.commit('alert/add', {
          text: 'Impossible de mettre à jour votre compte',
          color: 'error'
        })
        return
      }

      // finally, we log the user again because the token might not be valid anymore
      try {
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.newPassword.length > 0 ? this.newPassword : this.currentPassword
        })
      } catch (e) {
        this.loading = false
        this.$store.commit('alert/add', {
          text: 'Impossible de se reconnecter, contactez l\'administrateur',
          color: 'error'
        })
        return
      }

      this.currentPassword = ''
      this.newPassword = ''
      
      this.loading = false
      this.$store.commit('alert/add', {
        text: 'Votre compte a bien été mis à jour !',
        color: 'success'
      })
    }
  }
}
</script>